import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../utils/Button'
import intl from 'react-intl-universal'
import MagicWandSparklesIcon from '../icons/MagicWandSparkles'
import { track, trackAiCreation, trackButtonEnhanced } from 'utils/track'
import { useAppState } from 'graphql/hooks/useAppState'
import { capitalize, getMeta } from 'utils/functions'
import { useMutation, useQuery } from '@apollo/client'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars } from 'graphql/queries/user/getUser'
import Lottie from 'lottie-react'
import outlineSkeleton from 'common/json/lottie/OutlineSkeleton.json'
import { updateAppCache } from 'app'
import { sendBillingAdminLockedFeatureEmailMutation, SendBillingAdminLockedFeatureEmailVars } from 'graphql/mutations/emails/sendUserLockedFeatureEmail'
import MagicCredits from './components/MagicCredits'
import AdditionalInfo from './components/AdditionalInfo'
import CreationInProgress from './components/CreationInProgress'
import MagicUploader from './components/MagicUploader'
import OutlineContent from './components/OutlineContent'
import StepTitle from './components/StepTitle'
import MagicTextArea from './components/TextArea'
import { contentTypes, useCases, programLength, sessionLength, audienceOptions, voice, programDifficulty, languages } from './constants'
import { createSessionOrAction, createProgram, createProgramOutline } from './mutations'
import { wrapperInputCn, labelCn, inputAreaCn, recreateOutlineBtns } from './styles'
import { MagicCreatorProps, CustomFile, MagicCreditsResponse, FileData, MagicType } from './types'
import { checkAndShowAiCreditsModal } from './utilis'
import MagicSelect from './components/MagicSelect'
import MagicCheckbox from './components/Checkbox'
import { ProgramSummaryField, ProgramFinalCosts } from './components/ProgramSummary'
import { showModal2 } from 'components/utils/CustomModal'
import { extractGeminiErrorMessage } from 'utils/utils'
import AliceCarousel from 'react-alice-carousel'
import ExitMagicCreatorAlert from './components/ExitMagicCreatorAlert'
import { useDrawer } from 'context/drawer'

const OutlineAlert = ({onContinue, closeModal}) => {
  return ( 
      <div className="text-center">
          <img src="/images/magic-alert.svg" className="w-20 h-20 mx-auto mb-10" />
          <div className="font-bold text-lg mb-2">Are you sure you want to go back</div>
          <div>If you leave this screen, all created outlines will be lost.</div>
          <div className="flex gap-x-3 mx-auto mt-10 px-20">
              <Button className="w-full" text="Lose Outline(s)" onClick={onContinue} />
              <Button className="w-full" text="Stay" type="magic" onClick={closeModal} />
          </div>
      </div>
    );
}


const MagicCreator = ({ typeOfCreator, courseId, chapterId, closeModal, onPrevious, whiteLabelData, showZendeskWidget }: MagicCreatorProps) => {
  const typeOfCreatorProgram = typeOfCreator === 'course'
  const typeOfCreatorSession = typeOfCreator === 'chapter'
  const typeOfCreatorAction = typeOfCreator === 'action'
  const appState = useAppState()
  const [creationLoader, setCreationLoader] = useState(false)
  const [showDetails, setShowDetails] = useState(typeOfCreatorProgram ? true : false)
  const [showOptions, setShowOptions] = useState(false)
  const [showEditOutline, setShowEditOutline] = useState(false)
  const [prompt, setPrompt] = useState<string>('')
  const [selectedFiles, setSelectedFiles] = useState<CustomFile[]>([])
  const [showUploadIndicator, setShowUploadIndicator] = useState(false)
  const [showCreditsIndicator, setShowCreditsIndicator] = useState(true)
  const [disableCreateButton, setDisableCreateButton] = useState(false)
  const [programStep, setProgramStep] = useState(1)
  const [expanded, setExpanded] = useState(false)
  const [describeChanges, setDescribeChanges] = useState('')
  const [outlineLoading, setOutlineLoading] = useState(false)
  const [outlineData, setOutlineData] = useState('')
  const [otherAudienceValue, setOtherAudienceValue] = useState('')
  const [outlineDataArray, setOutlineDataArray] = useState<{ title: string; description: string; sessions: { sessionNumber: string; title: string; description: string }[] }[]>([]);
  const [outlinePosition, setOutlinePosition] = useState(0)
  const [outlineDataString, setOutlineDataString] = useState<string[]>([])
  const [outlineCreditsArray, setOutlineCreditsArray] = useState<MagicCreditsResponse['credits'][]>([])
  const [credits, setCredits] = useState<MagicCreditsResponse['credits']>({
    totalTokens: 0,
    totalCredits: 0,
    availableCredits: 0,
  })
  const [magicData, setMagicData] = useState({
    title: '',
    type: typeOfCreatorAction ? 'automatic' : undefined,
    description: '',
    courseDescription: '',
    useCase: 'automatic',
    language: 'automatic',
    programLength: typeOfCreator !== 'action' ? 'automatic' : undefined,
    audience: 'automatic',
    audienceOther: '',
    voice: 'automatic',
    fileMatchingType: '',
    fileUrls: [],
    uploadedFilesData: [] as FileData[],
    generateAiImages: true,
    learnerOutcomes: '',
    programDifficulty: 'automatic',
    programSuccess: '',
    useEmoji: true,
  } as MagicType)
  const { setCurrentStep } = useDrawer()

  const parseOutlineData = (data) => {
    if (!data) return { title: '', description: '', sessions: [] }
    const lines = data.split('\n') // Split the string into lines
    const titleLine = lines.find((line) => line.startsWith('Title of Program:')) // Extract the title
    const title = titleLine.replace('Title of Program: ', '').trim() // Remove prefix and trim whitespace
    const descriptionLine = lines.find((line) => line.startsWith('Description of Program:')) // Extract the description
    const description = descriptionLine.replace('Description of Program: ', '').trim() // Remove prefix and trim whitespace

    // Extract session data
    const sessions: { sessionNumber: string; title: string; description: string }[] = []
    let currentSession = { sessionNumber: '', title:'', description: '', }

    lines.forEach((line) => {
      line = line.trim() // Remove extra spaces
      if (line.startsWith('Session')) {
        if (currentSession) {
          sessions.push(currentSession); // Add the previous session to the list
        }
        currentSession = { sessionNumber: line, title: '', description: '' }
      } else if (line.startsWith('Title:')) {
        currentSession.title = line.replace('Title: ', '')
      } else if (line.startsWith('Description:')) {
        currentSession.description = line.replace('Description: ', '');
      }
    })

    // Push the last session
    if (currentSession) {
      sessions.push(currentSession)
    }

    return { title, description, sessions }
  }

  useEffect(() => {
    if (outlineData) {
      const parsedData = parseOutlineData(outlineData) // Process outlineData
        setOutlineDataString(prevArray => [...prevArray, outlineData]) // Append new data
        setOutlineDataArray(prevArray => [...prevArray, parsedData]) // Store parsed data
    }
  }, [outlineData])

  useEffect(() => {
    if (outlineData) {
      setOutlineCreditsArray(prevArray => {
        if (prevArray.length === outlineDataArray.length) {
          // Replace the last entry if the lengths match
          return [...prevArray.slice(0, -1), credits];
        } else {
          // Otherwise, append (only when outlineData changes)
          return [...prevArray, credits];
        }
      });
    }
  }, [outlineData, credits])

  useEffect(() => {
    if (outlineDataArray.length > 1 ) {
      const newPosition = outlineDataArray.length - 1;
      setOutlinePosition(newPosition);
    }
  }, [outlineDataArray])



  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'hide')
    }
  }, [])

  useEffect(() => {
    const hasMagicData =
      magicData.title ||
      magicData.learnerOutcomes ||
      magicData.programSuccess ||
      selectedFiles.length > 0 ||
      outlineDataString.length > 0
  
    setCurrentStep(hasMagicData ? 'magic' : '')
  }, [magicData.title, magicData.learnerOutcomes, magicData.programSuccess, selectedFiles, outlineDataString])

  const isAudienceOther = magicData.audience === 'Other'


  const { data: subData, loading: subLoading } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  const [sendBillingAdminLockedFeatureEmail] = useMutation<{ sendUserLockedFeatureEmail: string }, SendBillingAdminLockedFeatureEmailVars>(sendBillingAdminLockedFeatureEmailMutation)

  useEffect(() => {
    setDisableCreateButton(showUploadIndicator || showCreditsIndicator)
  }, [showUploadIndicator, showCreditsIndicator])

  const organizationId: string = getMeta(subData?.user.metadata, 'activeWorkspaceId')

  if (!appState) return null

  const [companyId] = appState.currentCompanyIdCourseId.split('-') || ['', '']

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setMagicData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const onChange = (e) => {
    setOtherAudienceValue(e.target.value); // Update the "Other" input value
  };

  const handleMagicActionCreate = async () => {
    // check if the user has enough credits
    const upsellModalShown = checkAndShowAiCreditsModal(credits, subData, organizationId, sendBillingAdminLockedFeatureEmail)
    if (upsellModalShown) return

    const isE2eTest = localStorage.getItem("e2eTest") === "true"
    if (!courseId) return
    setCreationLoader(true)

    const title = typeOfCreatorAction ? intl.get('magic_action_creation_in_progress_title') : intl.get('magic_session_creation_in_progress_title')
    const description = typeOfCreatorAction ? intl.get('magic_action_creation_in_progress_description') : intl.get('magic_session_creation_in_progress_description')

    const modalCreationInProcess = showModal2({
      component: <CreationInProgress title={title} description={description}/>,
      disableClickOutside: true,
      onlyContent: true,
      secondaryButton: false,
    })

    const enrichedFilesData = magicData.uploadedFilesData.map(file => {
      const matchingSelectedFile = selectedFiles.find(
        selected => selected.geminiName === file.name
      )

      return {
        ...file,
        filePrecision: matchingSelectedFile?.filePrecision || 'high_precision',
      }
    })

    const mappingFilesWithPrecision = selectedFiles.map((file) => ({
      filePrecision: file.filePrecision ? file.filePrecision : 'high_precision',
      geminiUri: file.urlGemini,
      geminiFileType: file.type,
      geminiState: file.uploadStatus,
      geminiName: file.name,
      geminiDisplayName: file.geminiDisplayName,
    }))

    const payload = {
      title: magicData.title,
      description: magicData.description,
      type: magicData.type,
      useCase: magicData.useCase,
      language: magicData.language,
      programLength: magicData.programLength,
      files: selectedFiles,
      filesPrecision: mappingFilesWithPrecision,
      geminiData: magicData.uploadedFilesData,
      generateAiImages: magicData.generateAiImages ?? true,
      learnerOutcomes: magicData.learnerOutcomes,
    }

    const response = await createSessionOrAction({
      courseId,
      chapterId,
      organizationId,
      companyId,
      inputData: {
        title: payload.title,
        description: payload.description,
        useCase: payload.useCase,
        language: payload.language,
        type: typeOfCreatorAction ? payload.type : undefined,
        sessionLength: typeOfCreatorSession ? payload.programLength : undefined,
        learnerOutcomes: payload.learnerOutcomes,
      },
      type: typeOfCreator,
      uploadedFiles: enrichedFilesData,
      token: appState.loggedInAs.token,
      dummy: isE2eTest || false,
      generateAiImages: payload.generateAiImages,
      totalCreditsCount: credits.totalCredits,
      totalTokensCount: credits.totalTokens,
    })

    modalCreationInProcess.close()
    if (response.error) {
      setCreationLoader(false)
      showModal2({
        title: `Error`,
        content: extractGeminiErrorMessage(response.error.message),
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
      return
    }

    closeModal()

    trackAiCreation({
      type: typeOfCreator,
      fileCount: selectedFiles.length,
      creditAvailable: credits.availableCredits,
      credits: credits.totalCredits,
      courseId,
      organizationId,
    })
  }
  const createMagicProgram = async () => {
    // check if the user has enough credits
    const upsellModalShown = checkAndShowAiCreditsModal(credits, subData, organizationId, sendBillingAdminLockedFeatureEmail)
    if (upsellModalShown) return

    const isE2eTest = localStorage.getItem("e2eTest") === "true"
    setCreationLoader(true)

    const title = intl.get('creating_magic_program_title')
    const description = intl.get('creating_magic_program_description')

    const modalCreationInProcess = showModal2({
      component: <CreationInProgress title={title} description={description}/>,
      disableClickOutside: true,
      onlyContent: true,
      secondaryButton: false,
    })

    const mappingFilesWithPrecision = selectedFiles.map((file) => ({
      filePrecision: file.filePrecision ? file.filePrecision : 'high_precision',
      geminiUri: file.urlGemini,
      geminiFileType: file.type,
      geminiState: file.uploadStatus,
      geminiName: file.name,
      geminiDisplayName: file.geminiDisplayName,
    }))

    const enrichedFilesData = magicData.uploadedFilesData.map(file => {
      const matchingSelectedFile = selectedFiles.find(
        selected => selected.geminiName === file.name
      )

      return {
        ...file,
        filePrecision: matchingSelectedFile?.filePrecision || 'high_precision',
      }
    })

    const payload = {
      title: magicData.title,
      description: magicData.description || '-', // magicData.courseDescription
      type: magicData.type,
      useCase: magicData.useCase,
      language: magicData.language,
      programLength: magicData.programLength,
      files: selectedFiles,
      filesPrecision: mappingFilesWithPrecision,
      geminiData: magicData.uploadedFilesData,
      audience: magicData.audience === 'Other' ? `Other: ${magicData.audienceOther}` : magicData.audience,
      voice: magicData.voice || 'automatic',
      generateAiImages: magicData.generateAiImages ?? true,
      programDifficulty: magicData.programDifficulty,
      learnerOutcomes: magicData.learnerOutcomes,
      programSuccess: magicData.programSuccess,
      useEmoji: magicData.useEmoji ?? true,
    }

    const response = await createProgram({
      organizationId,
      inputData: {
        title: payload.title,
        description: payload.description,
        useCase: payload.useCase,
        language: payload.language,
        audience: payload.audience,
        voice: payload.voice,
        outline: outlineDataString[outlinePosition],
        courseLength: payload.programLength,
        programDifficulty: payload.programDifficulty,
        learnerOutcomes: payload.learnerOutcomes,
        programSuccess: payload.programSuccess,
        useEmoji: payload.useEmoji ?? true,

      },
      uploadedFiles: magicData.uploadedFilesData,
      token: appState.loggedInAs.token,
      dummy: isE2eTest || false,
      generateAiImages: payload.generateAiImages,
      totalCreditsCount: outlineCreditsArray[outlinePosition].totalCredits || credits.totalCredits,
      totalTokensCount: outlineCreditsArray[outlinePosition].totalTokens || credits.totalTokens,
    })
    setTimeout(() => {
      modalCreationInProcess.close()
    }, 10000)
    if (response.error) {
      setCreationLoader(false)
      showModal2({
        title: `Error`,
        content: extractGeminiErrorMessage(response.error.message),
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
      return
    }

    closeModal()
    if (showZendeskWidget) {
      showZendeskWidget()
    }
    updateAppCache('isCreatingCourse', true)

    trackAiCreation({
      type: typeOfCreator,
      fileCount: selectedFiles.length,
      creditAvailable: credits.availableCredits,
      credits: credits.totalCredits,
      organizationId,
    })
  }

  const createOutline = async (additionalInfo?: string) => {
    setOutlineLoading(true)

    const mappingFilesWithPrecision = selectedFiles.map((file) => ({
      filePrecision: file.filePrecision ? file.filePrecision : 'high_precision',
      geminiUri: file.urlGemini,
      geminiFileType: file.type,
      geminiState: file.uploadStatus,
      geminiName: file.name,
      geminiDisplayName: file.geminiDisplayName,
    }))

    const payload = {
      title: magicData.title,
      description: magicData.description,
      describeChanges: describeChanges,
      additionalInfo: additionalInfo,
      type: magicData.type,
      useCase: magicData.useCase,
      language: magicData.language,
      programLength: magicData.programLength,
      files: selectedFiles,
      filesPrecision: mappingFilesWithPrecision,
      geminiData: magicData.uploadedFilesData,
      audience: magicData.audience === 'Other' ? `Other: ${magicData.audienceOther}` : magicData.audience,
      voice: magicData.voice,
      previousOutline: outlineDataString[outlinePosition] || null,
      programDifficulty: magicData.programDifficulty,
      learnerOutcomes: magicData.learnerOutcomes,
      programSuccess: magicData.programSuccess,
      useEmoji: magicData.useEmoji ?? true,
    }

    let response
    try {
      response = await Promise.race([
        createProgramOutline({
          organizationId,
          inputData: {
            title: payload.title,
            description: payload.description,
            describeChanges: payload.describeChanges,
            additionalInfo: payload.additionalInfo,
            useCase: payload.useCase,
            language: payload.language,
            courseLength: payload.programLength,
            audience: magicData.audience === 'Other' ? `Other: ${magicData.audienceOther}` : magicData.audience,
            voice: payload.voice,
            previousOutline: payload.previousOutline,
            programDifficulty: payload.programDifficulty,
            learnerOutcomes: payload.learnerOutcomes,
            programSuccess: payload.programSuccess,
            useEmoji: payload.useEmoji ?? true,
          },
          uploadedFiles: magicData.uploadedFilesData,
          token: appState.loggedInAs.token,
        }),
        new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), 60000)) // 60-second timeout

      ])
    } catch (error) {
      const errorMessage = (error instanceof Error && error.message === 'Request timed out')
        ? intl.get('error_request_timed_out') 
        : intl.get('error_generating_outline');

      response = {data: "", error: {message: errorMessage}}

      showModal2({
        title: `Error`,
        content: errorMessage,
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
    }

    setOutlineData(response.data)

    setOutlineLoading(false)
    track({
      event: typeOfCreator,
      variables: {
        organizationId,

      }
    })
  }


  const responsive = {
    10000000: {
      items: 1,
    },
    0: {
      items: 1,
    },
  };

  const showAlertOnClose = () => {
    if (magicData.title || magicData.learnerOutcomes || magicData.programSuccess || selectedFiles.length > 0 || outlineDataString.length > 0) {
      const modal = showModal2({
        component: <ExitMagicCreatorAlert
          onContinue={() => {
            onPrevious && onPrevious()
            if (showZendeskWidget) {
              showZendeskWidget()
            }
            setCurrentStep('')
            modal.close()
          }} 
          closeModal={() => modal.close()}
        />,
        onlyContent: true,
      })
    } else {
      if (onPrevious) {
        onPrevious()
      }
      if (showZendeskWidget) {
        showZendeskWidget()
      }
      setCurrentStep('')
  }}

  const showAlertOnOutlineBack = () => {
    const modal = showModal2({
      component: <OutlineAlert
        onContinue={() => {
          setProgramStep(1)
          setOutlineData('')
          setOutlineDataArray([])
          setOutlineDataString([])
          setOutlineCreditsArray([])
          setDescribeChanges('')
          modal.close()
        }}
        closeModal={() => modal.close()}
      />,
      onlyContent: true,
    })
  }

  return ( <div className={`${!expanded && 'sm:p-8'} relative h-[99.9%] overflow-auto`} style={whiteLabelData ? { backgroundColor: whiteLabelData.bgColor || '#f9f7f2' } : undefined}>
    {typeOfCreatorProgram && onPrevious && programStep === 1 ? <button className="flex gap-2 text-actions-multiple_choice font-bold mb-8 pt-8 pl-8 sm:pt-0 sm:pl-0" onClick={showAlertOnClose}>
      <ArrowLeftIcon className="w-6 h-6" /> {intl.get('global_back')}
    </button> : typeOfCreatorProgram && programStep === 2 ? <button className="flex gap-2 text-actions-multiple_choice font-bold mb-8 pt-8 pl-8 sm:pt-0 sm:pl-0" onClick={showAlertOnOutlineBack}>
      <ArrowLeftIcon className="w-6 h-6" /> {intl.get('global_back')}
    </button>
    : typeOfCreatorProgram && <button className="flex gap-2 text-actions-multiple_choice font-bold mb-8 pt-8 pl-8 sm:pt-0 sm:pl-0" onClick={() => {
      setProgramStep(2)
    }}>
    <ArrowLeftIcon className="w-6 h-6" /> {intl.get('global_back')}
  </button>
    }
    <div className="flex items-center justify-start w-full space-x-4 mb-2 px-8 sm:px-0">
      <img className="w-5 h-5 sm:w-7 sm:h-7" src="/images/wand-magic-sparkles.svg" alt="magic wand" />
      <div>
        <div className={`${typeOfCreatorProgram ? 'text-3xl': 'text-lg sm:text-2xl'}`}>{intl.get('gnowbe_ai_magic_creator')}</div>
      </div>
    </div>
    <div className={`${typeOfCreatorProgram ? 'text-sm px-8 sm:px-0': 'text-xs sm:text-sm px-8 sm:px-0'}`}>
      {typeOfCreatorAction ? intl.getHTML('magic_action_creator_description') : typeOfCreatorSession ? intl.getHTML('magic_session_creator_description') : intl.get('magic_program_creator_description')}
    </div>
    {typeOfCreator !== 'course' &&
    <div className="my-5 px-4 sm:px-0">
      <button data-test="provide-more-details" className="flex text-sm sm:text-base items-center justify-center" onClick={() => {
        trackButtonEnhanced({
          button: `Gnowbe Ai ${capitalize(typeOfCreator)} Provide More Details`,
          onScreen: `Magic ${capitalize(typeOfCreator)} Creator`,
        })
        setShowDetails(!showDetails)
        }}>
          {intl.get('magic_provide_more_details')}
        <ChevronDownIcon className={`w-5 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${showDetails ? 'rotate-180' : ''}`} />
      </button>
    </div>}
    {showDetails && programStep === 1 && (
      <div className={`mt-6 mb-8 ${typeOfCreatorProgram && 'p-5 rounded-lg bg-white'}`}>
        {typeOfCreatorProgram && <StepTitle title={intl.get('program_details_text')} description={intl.get('program_details_description')} />}
        <form className="space-y-6">
          <div className={wrapperInputCn}>
            <div className="flex flex-row space-x-2">
              <label htmlFor="magicActionTitle" className={labelCn}>{typeOfCreatorAction ? intl.get('global_action_title') : typeOfCreatorSession ? intl.get('chapter_placeholder_title') : intl.get('title')}</label>
              {typeOfCreatorProgram && <AdditionalInfo
                tooltipContent={<div>{intl.get('gnowbe_ai_progral_title_tooltip')}</div>}
                interactive={true} />}
            </div>
            <input
              name="title"
              id="magicActionTitle"
              placeholder={typeOfCreatorAction ? intl.get('global_action_title') : typeOfCreatorSession ? intl.get('chapter_placeholder_title') : intl.get('program_title')}
              className={`${inputAreaCn} shadow-[0_0px_10px_0px_rgba(0,0,0,0.2)]`}
              type="text"
              maxLength={200}
              value={magicData.title || ''}
              onChange={(e) => handleInputChange(e)}/>
              <div className='text-xs text-gray-500 text-right'>{magicData.title.length || 0}/200</div>
          </div>
          {typeOfCreatorProgram && <MagicUploader onChange={(e) => handleInputChange(e)} magicData={magicData} setMagicData={setMagicData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator="course" courseId={courseId} organizationId={organizationId} />}
          {typeOfCreatorAction && <MagicSelect
            name="type"
            labelText={intl.get('magic_choose_action_type')}
            content={Object.entries(contentTypes)}
            onChange={(e) => handleInputChange(e)}
            selectedValue={magicData.type}
          />}
          {!typeOfCreatorProgram && <MagicTextArea
              typeOfCreator={typeOfCreator}
              magicData={magicData.learnerOutcomes}
              handleInputChange={handleInputChange}
              label={typeOfCreatorSession ? intl.get('gnowbe_ai_learner_outcome_label_text_session') : intl.get('gnowbe_ai_learner_outcome_label_text_action')}
              id="magicLearnerOutcomes"
              name="learnerOutcomes"
              placeholder={typeOfCreatorSession ? intl.get('gnowbe_ai_learner_outcome_placeholder_session'): intl.get('gnowbe_ai_learner_outcome_placeholder_action')}
              additionalInfoText={typeOfCreatorSession ? intl.get('gnowbe_ai_learner_outcome_additional_info_session') : intl.get('gnowbe_ai_learner_outcome_additional_info_action')}
            />}
          {typeOfCreatorProgram && <>
            <MagicTextArea
              typeOfCreator={typeOfCreator}
              magicData={magicData.learnerOutcomes}
              handleInputChange={handleInputChange}
              label={intl.get('gnowbe_ai_learner_outcome_label_text')}
              id="magicLearnerOutcomes"
              name="learnerOutcomes"
              placeholder={intl.get('gnowbe_ai_learner_outcome_placeholder')}
              additionalInfoText={intl.get('gnowbe_ai_learner_outcome_additional_info')}
            />
            <MagicTextArea
              typeOfCreator={typeOfCreator}
              magicData={magicData.programSuccess}
              handleInputChange={handleInputChange}
              label={intl.get('gnowbe_ai_program_success_label_text')}
              id="magicProgramSuccess"
              name="programSuccess"
              placeholder={intl.get('gnowbe_ai_program_success_placeholder')}
              additionalInfoText={intl.get('gnowbe_ai_program_success_additional_info')}
            />
          </>}

          <div>
            {typeOfCreatorSession && <MagicUploader onChange={(e) => handleInputChange(e)} magicData={magicData} setMagicData={setMagicData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator={typeOfCreator} courseId={courseId} organizationId={organizationId} />}
          </div>
          <button data-test="more-options" className="flex text-sm sm:text-base items-center justify-center mt-2" onClick={(e) => {
            e.preventDefault()
            trackButtonEnhanced({
              button: `Gnowbe Ai ${capitalize(typeOfCreator)} More Options`,
              onScreen: `Magic ${capitalize(typeOfCreator)} Creator`,
            })
            setShowOptions(!showOptions)
          }}>
            {intl.get('magic_more_options')}
            <ChevronDownIcon className={`w-5 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${showOptions ? 'rotate-180' : ''}`} />
          </button>
          {showOptions && (
            <div className="flex flex-col space-y-2 w-full bg-[#F9F8F8] rounded-lg relative">
              <div className="mb-4">
                {typeOfCreatorAction && <MagicUploader onChange={(e) => handleInputChange(e)} magicData={magicData} setMagicData={setMagicData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator={typeOfCreator} courseId={courseId} organizationId={organizationId} />}
              </div>
              <div className="flex flex-col sm:flex-row items-center justify-between">
                <MagicSelect
                  name="useCase"
                  labelText={intl.get('magic_use_case')}
                  content={Object.entries(useCases)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={magicData.useCase}
                  hasInfo={true}
                  tooltipContent={intl.get('magic_use_case_tooltip')}
                />
                <MagicSelect
                  name="language"
                  labelText={intl.get('magic_language')}
                  content={Object.entries(languages)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={magicData.language}
                  hasInfo={true}
                  tooltipContent={intl.get('magic_language_tooltip')}
                />
                {
                  (typeOfCreatorProgram || typeOfCreatorSession) && (
                  <MagicSelect
                    name="programLength"
                    labelText={typeOfCreatorProgram ? intl.get('magic_program_length') : intl.get('magic_session_length')}
                    content={Object.entries(typeOfCreatorProgram ? programLength : sessionLength)}
                    onChange={(e) => handleInputChange(e)}
                    selectedValue={magicData.programLength}
                    hasInfo={true}
                    tooltipContent={`${
                      typeOfCreatorProgram
                        ? intl.get('magic_program_length_info')
                        : intl.get('magic_session_length_info')
                    }`}
                  />
                  )
                }
              </div>
              {typeOfCreatorProgram && <div className="flex flex-col sm:flex-row items-center justify-between">
                <div className={`${wrapperInputCn} sm:w-1/3 order-3 sm:order-none`}>
                  <div className="flex flex-row space-x-2">
                    <label htmlFor="audience" className={labelCn}>{intl.get('gnowbe_ai_target_audience')}</label>
                    <AdditionalInfo tooltipContent={intl.get('gnowbe_ai_target_audience_tooltip')} interactive />
                  </div>
                  <select
                    id="audience"
                    name="audience"
                    className={`${inputAreaCn} shadow-[0_0px_10px_0px_rgba(0,0,0,0.2)]`}
                    value={magicData.audience || ''}
                    onChange={(e) => handleInputChange(e)}>
                    <option value="automatic">{intl.get('automatic')}</option>
                    {Object.entries(audienceOptions).map(([key, value], index) => {
                      return <option value={key} key={`${key}-${index}`}>{value}</option>
                    })}
                  </select>
                  {isAudienceOther && <input
                      name="audienceOther"
                      id="magicAudience"
                      placeholder={intl.get('gnowbe_ai_target_audience_placeholder')}
                      className={`${inputAreaCn} shadow-[0_0px_10px_0px_rgba(0,0,0,0.2)] w-auto sm:w-72 absolute bottom-[72px] sm:bottom-12 left-4`}
                      type="text"
                      maxLength={200}
                      value={magicData.audienceOther || ''}
                      onChange={(e) => handleInputChange(e)}
                      />}
                  </div>
                <MagicSelect
                  name="voice"
                  labelText={intl.get('gnowbe_ai_tone_of_voice')}
                  content={Object.entries(voice)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={magicData.voice}
                  hasInfo={true}
                  tooltipContent={intl.get('gnowbe_ai_tone_of_voice_tooltip')}
                />
                <MagicSelect
                  name="programDifficulty"
                  labelText={intl.get('gnowbe_ai_program_difficulty')}
                  content={Object.entries(programDifficulty)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={magicData.programDifficulty}
                  hasInfo={true}
                  tooltipContent={intl.get('gnowbe_ai_program_difficulty_tooltip')}
                />
              </div>}
              <div className={`mt-4 flex gap-4 px-4 ${isAudienceOther ? 'pt-12' : 'pt-2'} pb-4`}>
                <MagicCheckbox name={intl.get('gnowbe_ai_generate_images')} setMagicData={setMagicData} fieldKey="generateAiImages" additionalInfoText={intl.get('gnowbe_ai_generate_images_tooltip')} />
                <MagicCheckbox name={intl.get('gnowbe_ai_use_emojis')} setMagicData={setMagicData} fieldKey="useEmoji" additionalInfoText={intl.get('gnowbe_ai_use_emojis_tooltip')} />
              </div>
            </div>
          )}
        </form>
      </div>
    )}

    {programStep === 2 && <div className={`mt-6 mb-8 ${typeOfCreatorProgram && !expanded && 'p-5 rounded-lg bg-white'}`}>
      <StepTitle title={intl.get('gnowbe_ai_program_outline')} description={intl.get('gnowbe_ai_program_outline_description')} />
      <form className={`${expanded ? 'static' : 'relative'}`}>
        <div className="flex flex-col">
          <div className={`bg-[#F9F8F8] rounded-lg pl-8 pr-4 py-10 overflow-hidden ${expanded && 'absolute top-0 h-full sm:h-screen w-full rounded-none z-50'}`} data-test="program-outline">
            {!outlineLoading && <div className="relative">
              {!expanded
                ? <img className="w-5 h-5 absolute -top-7 right-0 cursor-pointer" src="/images/opens-in-full-new.svg" alt="open full image" onClick={() => setExpanded(true)} />
                : <img className="w-5 h-5 absolute -top-7 right-0 cursor-pointer" src="/images/minus.svg" alt="close full image" onClick={() => setExpanded(false)} />
                }
            </div>}
            {outlineLoading && <div className="h-96 -ml-5 -mt-5 mb-5">
              <Lottie animationData={outlineSkeleton} />
            </div>}
            {outlineData && <div className={`no-max-width ${outlineDataArray.length === 1 && '-mb-5'}`} key={outlineDataArray.length}>
              <AliceCarousel
                disableDotsControls
                responsive={responsive}
                activeIndex={outlinePosition + 1}
                onSlideChanged={(e) => {
                  outlineDataArray.filter((t, i) => {
                    if (i === e.item) {
                      setOutlinePosition(i)
                    }
                  })
                }}

                renderPrevButton={({ isDisabled }) => (
                  <button type="button" className={`absolute bottom-4 right-[86px] sm:right-14 bg-magic-light disabled:bg-magic-lighter disabled:cursor-not-allowed rounded-full shadow-md p-2 ${outlineDataArray.length <= 1 && 'invisible'}`} disabled={isDisabled} data-test="outline-prev-btn">
                    <ArrowLeftIcon className="h-4 w-4" />
                  </button>
                )}
                renderNextButton={({ isDisabled }) => (
                  <button type="button" className={`absolute bottom-4 right-12 sm:right-5 bg-magic-light disabled:bg-magic-lighter disabled:cursor-not-allowed rounded-full shadow-md p-2 ${outlineDataArray.length <= 1 && 'invisible'}`} disabled={isDisabled} data-test="outline-next-btn">
                    <ArrowRightIcon className="h-4 w-4" />
                  </button>
                )}
                items={outlineDataArray.map((outline, i) => (
                  <OutlineContent key={i} parsedData={outline} expanded={expanded} />
                ))}
              />
              {outlineDataArray.length > 1 && <div className="absolute bottom-5 left-8 text-sm">
                <strong>Outline:</strong> {`${outlinePosition + 1}/${outlineDataArray.length}`}
              </div>}
            </div>}
          </div>
        </div>
      </form>
      {!expanded && <button data-test="more-options" className="flex text-sm sm:text-base items-center justify-center pt-4 pb-2 sm:pb-0" onClick={(e) => {
        e.preventDefault()
        trackButtonEnhanced({
          button: `Gnowbe Ai ${capitalize(typeOfCreator)} Edit Outline`,
          onScreen: `Magic ${capitalize(typeOfCreator)} Creator`,
        })
        setShowEditOutline(!showEditOutline)
      }}>
        {intl.get('gnowbe_ai_edit_outline')}
        <ChevronDownIcon className={`w-5 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${showEditOutline ? 'rotate-180' : ''}`} />
      </button>}
      {showEditOutline && <>
        <div className="flex flex-col sm:flex-row w-full items-center gap-y-3 sm:gap-x-3 justify-between mt-2">
          <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-y-3 sm:gap-y-0 sm:gap-x-3">
            <Button text={intl.get('gnowbe_ai_regenerate')} className={recreateOutlineBtns} onClick={() => {
              setOutlineData('')
              createOutline('Regenerate')
              trackButtonEnhanced({
                button: 'Gnowbe Ai Course Regenerate',
                onScreen: 'Magic Program Creator',
              })
              }} />
            <Button text={intl.get('gnowbe_ai_make_fun')} className={recreateOutlineBtns} onClick={() => {
              setOutlineData('')
              createOutline('Make it more fun')
              trackButtonEnhanced({
                button: 'Gnowbe Ai Course Make It More Fun',
                onScreen: 'Magic Program Creator',
              })
              }} />
          </div>
          <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-y-3 sm:gap-y-0 sm:gap-x-3">
            <Button text={intl.get('gnowbe_ai_make_professional')} className={recreateOutlineBtns} onClick={() => {
              setOutlineData('')
              createOutline('Make it more professional')
              trackButtonEnhanced({
                button: 'Gnowbe Ai Course Make It More Professional',
                onScreen: 'Magic Program Creator',
              })
              }} />
            <Button text={intl.get('add_another_session')} className={recreateOutlineBtns} onClick={() => {
              setOutlineData('')
              createOutline('Add One More Session')
              trackButtonEnhanced({
                button: 'Gnowbe Ai Course Add Another Session',
                onScreen: 'Magic Program Creator',
              })
              }} />
          </div>
        </div>
        <div className={`${wrapperInputCn} mt-6 ${!expanded && 'relative'}`}>
          <div className={`${!expanded ? 'absolute' : 'hidden'} bottom-12 right-8`} >
              <Button text={intl.get('gnowbe_ai_update_outline')} className={`${recreateOutlineBtns} ${describeChanges ? 'cursor-pointer' : 'cursor-not-allowed'}`} disabled={!describeChanges} onClick={() => {
            if (!describeChanges) return
            setOutlineData('')
            createOutline(describeChanges)
            setDescribeChanges('')
            trackButtonEnhanced({
              button: 'Gnowbe Ai Course Send Outline Changes',
              onScreen: 'Magic Program Creator',
            })
            }} />
          </div>
          <label htmlFor="magicDescribeChanges" className={labelCn}>{intl.get('gnowbe_ai_describe_main_goals')}</label>
          <textarea
            name="describeChanges"
            id="magicDescribeChanges"
            placeholder={intl.get('gnowbe_ai_describe_main_goals_placehoder')}
            className={`${inputAreaCn} shadow-[0_0px_10px_0px_rgba(0,0,0,0.2)] min-h-[144px]`}
            value={describeChanges || ''}
            onChange={(e) => setDescribeChanges(e.target.value)}
            maxLength={1000}
          />
          <div className='text-xs text-gray-500 text-right'>{describeChanges.length}/1000</div>
        </div>
      </>}
    </div>}
    {programStep === 3 && <div>
        <div className={`mt-6 mb-8 ${typeOfCreatorProgram && !expanded && 'p-5 rounded-lg bg-white'}`}>
        <StepTitle title={intl.get('gnowbe_ai_summary_title')} description={intl.get('gnowbe_ai_summary_description')} />
        <ProgramSummaryField outLineData={outlineDataArray[outlinePosition]} />
        {magicData.uploadedFilesData.length > 0 && <ProgramSummaryField uploadData={magicData.uploadedFilesData} />}
        <ProgramSummaryField style={magicData} />
        <ProgramSummaryField settings={magicData} />
      </div>
        <ProgramFinalCosts credits={outlineCreditsArray[outlinePosition] || credits} />
    </div>}

    <div className={`flex flex-col sm:flex-row ${programStep !== 3 ? 'items-start justify-start sm:items-center sm:justify-between' : 'items-start justify-start sm:items-end sm:justify-end'} mt-10 sm:space-x-4 pr-4 sm:pr-0 pl-8 pb-8 sm:pl-0 sm:pb-0`}>
      {programStep !== 3 && <MagicCredits
        magicData={magicData}
        creditsTokens={credits}
        setCredits={setCredits}
        showCreditsIndicator={showCreditsIndicator}
        setShowCreditsIndicator={setShowCreditsIndicator}
        courseId={courseId}
        organizationId={organizationId}
        type={typeOfCreator}
        programLength={magicData.programLength}
        outlineData={outlineDataString[outlinePosition]}
        outlineDataStringArray={outlineDataString}
        outlineCreditsArray={outlineCreditsArray}
        outlinePosition={outlinePosition}
      />}
      {typeOfCreatorProgram && programStep === 1 ? <div className="flex w-full mt-5 items-center justify-end sm:w-auto sm:mt-0 gap-3">
        <Button
          text={intl.get('next_text')}
          type="magic"
          className="px-3 sm:px-6 w-full sm:w-auto"
          disabled={!magicData.title || disableCreateButton || (magicData.audience === 'Other' && magicData.audienceOther === '')}
          onClick={() => {
            setOutlineData('')
            createOutline()
            setProgramStep(2)
            trackButtonEnhanced({
              button: 'Gnowbe Ai Course Next to step 2',
              onScreen: 'Magic Program Creator',
            })
          }}
          svgRightIcon={<ArrowRightIcon className="h-6 w-6 font-bold fill-white ml-2" />}
        />
      </div>  : typeOfCreatorProgram && programStep === 2 ? <div className="flex w-full mt-5 items-center justify-between sm:w-auto sm:mt-0 gap-3">
        <Button
            text={intl.get('global_back')}
            type="magic"
            className={`${recreateOutlineBtns} px-3 sm:px-6 w-full sm:w-auto`}
            onClick={() => {
              showAlertOnOutlineBack()
              trackButtonEnhanced({
                button: 'Gnowbe Ai Course Back to step 1',
                onScreen: 'Magic Program Creator',
              })
            }}
            svgIcon={<ArrowLeftIcon className="h-6 w-6 font-bold fill-[#101010] mr-2" />}
          />
        <Button
            text={intl.get('next_text')}
            type="magic"
            disabled={!outlineData || outlineLoading || (magicData.audience === 'Other' && magicData.audienceOther === '')}
            className="px-3 sm:px-6 w-full sm:w-auto"
            onClick={() => {
              setProgramStep(3)
              trackButtonEnhanced({
                button: 'Gnowbe Ai Course Next to step 3',
                onScreen: 'Magic Program Creator',
              })
            }}
            svgRightIcon={<ArrowRightIcon className="h-6 w-6 font-bold fill-white ml-2" />}
          />
      </div>

      : typeOfCreatorProgram && <div className="flex w-full mt-5 items-center justify-between sm:w-auto sm:mt-0 gap-3">
        <Button
          text={intl.get('global_back')}
          type="magic"
          className={`${recreateOutlineBtns} px-3 sm:px-6`}
          onClick={() => {
            setProgramStep(2)
            trackButtonEnhanced({
              button: 'Gnowbe Ai Course Back to step 2',
              onScreen: 'Magic Program Creator',
            })
          }}
          svgIcon={<ArrowLeftIcon className="h-6 w-6 font-bold fill-[#101010] mr-2" />}
        />
        <Button
          text={intl.get('magic_create_now')}
          type="magic"
          className="px-3 sm:px-6 w-full sm:w-auto"
          disabled={disableCreateButton || outlineLoading}
          onClick={() => {
            createMagicProgram()
            trackButtonEnhanced({
              button: 'Gnowbe Ai Course Create Now',
              onScreen: 'Magic Program Creator',
            })
          }}
          svgIcon={<MagicWandSparklesIcon className="fill-white mr-4 h-" />}
          />
      </div>}
      {typeOfCreator !== 'course' && <Button
        text={intl.get('magic_create_now')}
        type="magic"
        className="px-3 sm:px-6"
        disabled={disableCreateButton}
        onClick={() => {
          handleMagicActionCreate()
          trackButtonEnhanced({
            button: `Gnowbe Ai ${typeOfCreator} Create Now`,
            onScreen: `'Magic ${typeOfCreator} Creator'`,
          })
        }}
        svgIcon={<MagicWandSparklesIcon className="fill-white mr-4" />}
        />}
    </div>
  </div>
  )
}

export default MagicCreator